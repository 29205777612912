@font-face {
    font-family: 'gotham';
    src: local('GothamUltraItalic'), url(../assets/fonts/GothamUltraItalic.otf) format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamBlack'), url(../assets/fonts/GothamBlack.otf) format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamBold'), url(../assets/fonts/GothamBold.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamBoldItalic'), url(../assets/fonts/GothamBoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamBook'), url(../assets/fonts/GothamBook.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamBookItalic'), url(../assets/fonts/GothamBookItalic.otf) format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamMedium'), url(../assets/fonts/GothamMedium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamMediumItalic'), url(../assets/fonts/GothamMediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamLight'), url(../assets/fonts/GothamLight.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamLightItalic'), url(../assets/fonts/GothamLightItalic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamXLight'), url(../assets/fonts/GothamXLight.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamXLightItalic'), url(../assets/fonts/GothamXLightItalic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamThin'), url(../assets/fonts/GothamThin.otf) format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: local('GothamThinItalic'), url(../assets/fonts/GothamThinItalic.otf) format('opentype');
    font-weight: 200;
    font-style: italic;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

body {
    background: #FDFDFD;
    color: #29292e;
    overflow: hidden !important; 
}